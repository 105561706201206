import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/auth';
import 'firebase/compat/functions';

const config = {
    apiKey: "AIzaSyC51ZDN3Lxto6AzQiApeCr55X4SruqsL0I",
    authDomain: "autoparts-95d56.firebaseapp.com",
    databaseURL: "https://autoparts-95d56.firebaseio.com/",
    projectId: "autoparts-95d56"
};

if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

export const database = firebase.database();
export const auth = firebase.auth();
export const functions = firebase.app().functions('europe-west1');