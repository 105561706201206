export const AUTHENTICATION_REQUEST = 'AUTHENTICATION_REQUEST';
export const AUTHENTICATION_GET_TOKEN = 'AUTHENTICATION_GET_TOKEN';
export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';
export const AUTHENTICATION_FAILURE = 'AUTHENTICATION_FAILURE';
export const AUTHENTICATION_ROLE_FAILURE = 'AUTHENTICATION_ROLE_FAILURE';

export const REGISTRATION_REQUEST = 'REGISTRATION_REQUEST';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE';

export const MESSAGE_SHOW =  'MESSAGE_SHOW';

export const LOG_OUT_REQUEST = 'LOG_OUT_REQUEST';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const LOG_OUT_FAILURE = 'LOG_OUT_FAILURE';

export const LOAD_CLIENT_REQUEST = 'LOAD_CLIENT_REQUEST';
export const LOAD_CLIENT_SUCCESS = 'LOAD_CLIENT_SUCCESS';
export const LOAD_CLIENT_FAILURE = 'LOAD_CLIENT_FAILURE';
export const CLIENT_DOESNT_EXIST = 'CLIENT_DOESNT_EXIST';

export const LOAD_PAYMENTS_REQUEST = 'LOAD_PAYMENTS_REQUEST';
export const LOAD_PAYMENTS_SUCCESS = 'LOAD_PAYMENTS_SUCCESS';
export const LOAD_PAYMENTS_FAILURE = 'LOAD_PAYMENTS_FAILURE';

export const LOAD_ORDERS_REQUEST = 'LOAD_ORDERS_REQUEST';
export const LOAD_ORDERS_SUCCESS = 'LOAD_ORDERS_SUCCESS';
export const LOAD_ORDERS_FAILURE = 'LOAD_ORDERS_FAILURE';

export const LOAD_RESERVES_REQUEST = 'LOAD_RESERVES_REQUEST';
export const LOAD_RESERVES_SUCCESS = 'LOAD_RESERVES_SUCCESS';
export const LOAD_RESERVES_FAILURE = 'LOAD_RESERVES_FAILURE';

export const DELETE_ORDERS_REQUEST = 'DELETE_ORDERS_REQUEST';
export const DELETE_ORDERS_SUCCESS = 'DELETE_ORDERS_SUCCESS';
export const DELETE_ORDERS_FAILURE = 'DELETE_ORDERS_FAILURE';

export const DELETE_RESERVES_REQUEST = 'DELETE_RESERVES_REQUEST';
export const DELETE_RESERVES_SUCCESS = 'DELETE_RESERVES_SUCCESS';
export const DELETE_RESERVES_FAILURE = 'DELETE_RESERVES_FAILURE';

export const LOAD_UNBLOCK_RECORDS_REQUEST = 'LOAD_UNBLOCK_RECORDS_REQUEST';
export const LOAD_UNBLOCK_RECORDS_SUCCESS = 'LOAD_UNBLOCK_RECORDS_SUCCESS';
export const LOAD_UNBLOCK_RECORDS_FAILURE = 'LOAD_UNBLOCK_RECORDS_FAILURE';

export const UNBLOCK_CLIENT_REQUEST = 'UNBLOCK_CLIENT_REQUEST';
export const UNBLOCK_CLIENT_SUCCESS = 'UNBLOCK_CLIENT_SUCCESS';
export const UNBLOCK_CLIENT_FAILURE = 'UNBLOCK_CLIENT_FAILURE';

export const UPDATE_ORDER_QUANTITY_REQUEST = 'UPDATE_ORDER_QUANTITY_REQUEST';
export const UPDATE_ORDER_QUANTITY_SUCCESS = 'UPDATE_ORDER_QUANTITY_SUCCESS';
export const UPDATE_ORDER_QUANTITY_FAILURE = 'UPDATE_ORDER_QUANTITY_FAILURE';

export const UPDATE_RESERVE_QUANTITY_REQUEST = 'UPDATE_RESERVE_QUANTITY_REQUEST';
export const UPDATE_RESERVE_QUANTITY_SUCCESS = 'UPDATE_RESERVE_QUANTITY_SUCCESS';
export const UPDATE_RESERVE_QUANTITY_FAILURE = 'UPDATE_RESERVE_QUANTITY_FAILURE';

export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";

export const LOAD_CLIENT_STATISTIC_REQUEST = 'LOAD_CLIENT_STATISTIC_REQUEST';
export const LOAD_CLIENT_STATISTIC_SUCCESS = 'LOAD_CLIENT_STATISTIC_SUCCESS';
export const LOAD_CLIENT_STATISTIC_FAILURE = 'LOAD_CLIENT_STATISTIC_FAILURE';

export const LOAD_VENDOR_STATISTIC_REQUEST = 'LOAD_VENDOR_STATISTIC_REQUEST';
export const LOAD_VENDOR_STATISTIC_SUCCESS = 'LOAD_VENDOR_STATISTIC_SUCCESS';
export const LOAD_VENDOR_STATISTIC_FAILURE = 'LOAD_VENDOR_STATISTIC_FAILURE';

export const LOAD_STATISTIC_BY_VENDOR_REQUEST = 'LOAD_STATISTIC_BY_VENDOR_REQUEST';
export const LOAD_STATISTIC_BY_VENDOR_SUCCESS = 'LOAD_STATISTIC_BY_VENDOR_SUCCESS';
export const LOAD_STATISTIC_BY_VENDOR_FAILURE = 'LOAD_STATISTIC_BY_VENDOR_FAILURE';

export const SET_STATISTIC_PERIOD = 'SET_STATISTIC_PERIOD';

export const SHOW_CLIENT_PRICE = 'SHOW_CLIENT_PRICE';
export const HIDE_CLIENT_PRICE = 'HIDE_CLIENT_PRICE';

export const CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE';

export const CREATE_RESERVE_REQUEST = 'CREATE_RESERVE_REQUEST';
export const CREATE_RESERVE_SUCCESS = 'CREATE_RESERVE_SUCCESS';
export const CREATE_RESERVE_FAILURE = 'CREATE_RESERVE_FAILURE';

export const UPDATE_PRICE_REQUEST = 'UPDATE_PRICE_REQUEST';
export const UPDATE_PRICE_SUCCESS = 'UPDATE_PRICE_SUCCESS';
export const UPDATE_PRICE_FAILURE = 'UPDATE_PRICE_FAILURE';

export const LOAD_BY_NUMBER_REQUEST = 'LOAD_BY_NUMBER_REQUEST';
export const LOAD_BY_NUMBER_SUCCESS = 'LOAD_BY_NUMBER_SUCCESS';
export const LOAD_BY_NUMBER_FAILURE = 'LOAD_BY_NUMBER_FAILURE';

export const LOAD_BY_BRAND_REQUEST = 'LOAD_BY_BRAND_REQUEST';
export const LOAD_BY_BRAND_SUCCESS = 'LOAD_BY_BRAND_SUCCESS';
export const LOAD_BY_BRAND_FAILURE = 'LOAD_BY_BRAND_FAILURE';

export const LOAD_RECONCILIATION_REQUEST = 'LOAD_RECONCILIATION_REQUEST';
export const LOAD_RECONCILIATION_SUCCESS = 'LOAD_RECONCILIATION_SUCCESS';
export const LOAD_RECONCILIATION_FAILURE = 'LOAD_RECONCILIATION_FAILURE';

export const LOAD_PAYMENT_HISTORY_REQUEST = 'LOAD_PAYMENT_HISTORY_REQUEST';
export const LOAD_PAYMENT_HISTORY_SUCCESS = 'LOAD_PAYMENT_HISTORY_SUCCESS';
export const LOAD_PAYMENT_HISTORY_FAILURE = 'LOAD_PAYMENT_HISTORY_FAILURE';

export const LOAD_RETURN_HISTORY_REQUEST = 'LOAD_RETURN_HISTORY_REQUEST';
export const LOAD_RETURN_HISTORY_SUCCESS = 'LOAD_RETURN_HISTORY_SUCCESS';
export const LOAD_RETURN_HISTORY_FAILURE = 'LOAD_RETURN_HISTORY_FAILURE';

export const LOAD_SALE_HISTORY_REQUEST = 'LOAD_SALE_HISTORY_REQUEST';
export const LOAD_SALE_HISTORY_SUCCESS = 'LOAD_SALE_HISTORY_SUCCESS';
export const LOAD_SALE_HISTORY_FAILURE = 'LOAD_SALE_HISTORY_FAILURE';

export const LOAD_CURRENCY_RATE_REQUEST = 'LOAD_CURRENCY_RATE_REQUEST';
export const LOAD_CURRENCY_RATE_SUCCESS = 'LOAD_CURRENCY_RATE_SUCCESS';
export const LOAD_CURRENCY_RATE_FAILURE = 'LOAD_CURRENCY_RATE_FAILURE';

export const LOAD_ANALOGS_REQUEST = 'LOAD_ANALOGS_REQUEST';
export const LOAD_ANALOGS_SUCCESS = 'LOAD_ANALOGS_SUCCESS';
export const LOAD_ANALOGS_FAILURE = 'LOAD_ANALOGS_FAILURE';

export const LOAD_PHOTOS_REQUEST = 'LOAD_PHOTOS_REQUEST';
export const LOAD_PHOTOS_SUCCESS = 'LOAD_PHOTOS_SUCCESS';
export const LOAD_PHOTOS_FAILURE = 'LOAD_PHOTOS_FAILURE';

export const LOAD_DELIVERY_DATE_REQUEST = 'LOAD_DELIVERY_DATE_REQUEST';
export const LOAD_DELIVERY_DATE_SUCCESS = 'LOAD_DELIVERY_DATE_SUCCESS';
export const LOAD_DELIVERY_DATE_FAILURE = 'LOAD_DELIVERY_DATE_FAILURE';

export const UPDATE_APP_STATE_REQUEST = 'UPDATE_APP_STATE_REQUEST';
export const UPDATE_APP_STATE_SUCCESS = 'UPDATE_APP_STATE_SUCCESS';
export const UPDATE_APP_STATE_FAILURE = 'UPDATE_APP_STATE_FAILURE';

export const SUBSCRIBE_TO_APP_STATE_UPDATE_REQUEST = 'SUBSCRIBE_TO_APP_STATE_UPDATE_REQUEST';
export const SUBSCRIBE_TO_APP_STATE_UPDATE_FAILURE = 'SUBSCRIBE_TO_APP_STATE_UPDATE_FAILURE';
export const UNSUBSCRIBE_TO_APP_STATE_UPDATE_REQUEST = 'UNSUBSCRIBE_TO_APP_STATE_UPDATE_REQUEST';

export const APP_STATE_UPDATED = 'APP_STATE_UPDATED';



